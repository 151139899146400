import {
  FacebookFilled,
  MessageOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import {
  Button,
  Carousel,
  Col,
  Empty,
  Flex,
  Row,
  Space,
  Statistic,
} from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useCallback, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import { getPostDetails, postInquiry } from "../../utils/apiService";
import { DeviceType } from "../../utils/constants.js";
import { CustomContainer } from "../common/components/container.jsx";
import GoBack from "../common/goBack.jsx";
import { _STYLES } from "../common/utils/customStyles.js";
import Image from "../common/utils/image.jsx";
import { useMetaContext } from "../core/contexts/MetaContextProvider.jsx";
import { useNotificationContext } from "../core/contexts/NotificationContextProvider";
import { _THEME } from "../theme.js";
import InquiryModal from "./modals/inquiryModal.jsx";
import DownloadPromptModal from "./modals/downloadPromptModal.jsx";

const {
  sectionHeader,
  textMd1,
  miniHeader,
  subHeader,
  fontLight,
  paraLine,
  headerLine,
  fontMid,
  blogDetailImage,
  mb2,
  stripLeft,
  h100,
  colorPrimary,
  blogInner,
  textSm3,
  colorText,
  fontRegular,
  w100,
  colorSubText,
  flexColumn,
  statWrap,
  customFont,
  mt2,
} = _STYLES;

const useStyles = createUseStyles({
  sectionHeader,
  textMd1,
  miniHeader,
  subHeader,
  fontLight,
  paraLine,
  headerLine,
  fontMid,
  blogDetailImage,
  mb2,
  stripLeft,
  h100,
  colorPrimary,
  blogInner,
  textSm3,
  colorText,
  fontRegular,
  w100,
  colorSubText,
  flexColumn,
  statWrap,
  customFont,
  mt2,
});

const PostDetails = () => {
  const { deviceType } = useMetaContext();

  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);
  const [isDownloadPromptModalOpen, setIsDownloadPromptModalOpen] =
    useState(false);

  const [isInquirySending, setIsInquirySending] = useState(false);

  const isMobile = deviceType === DeviceType.mobile;

  const classes = useStyles();
  const { postId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState(null);

  const navigate = useNavigate();

  const { notify } = useNotificationContext();

  const fetchDetails = useCallback(async () => {
    await getPostDetails(postId)
      .then((post) => setPost(post))
      .finally(() => setIsLoading(false));
  }, [postId]);

  const backToHome = useCallback(() => navigate("/"), [navigate]);

  const toggleInquiryModal = useCallback(() => {
    setIsInquiryModalOpen((open) => !open);
  }, [setIsInquiryModalOpen]);

  const toggleDownloadPromptModal = useCallback(() => {
    setIsDownloadPromptModalOpen((open) => !open);
  }, [setIsDownloadPromptModalOpen]);

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomContainer padTop="lg" padBottom="lg">
      <GoBack text={"Back to Posts"} to={"/browse"} />
      {isLoading ? (
        <PostDetailsSkeleton />
      ) : post === null ? (
        <Empty
          imageStyle={{ height: 350 }}
          image="/images/post-not-found.png"
          description={
            <Space size={[0, 24]} className={classes.mt2} direction="vertical">
              <h1 className={classes.subHeader + " " + classes.customFont}>
                Post not found
              </h1>
              <Button onClick={backToHome} type="primary">
                Back to Home
              </Button>
            </Space>
          }
        ></Empty>
      ) : (
        <Row
          gutter={[
            { sm: 0, xs: 0, md: 24 },
            { xs: 16, sm: 16 },
          ]}
        >
          <Col sm={24} xs={24} md={12}>
            {post.thumbnailUrls.length === 0 ? (
              <Image className={classes.blogDetailImage} />
            ) : (
              <Carousel autoplaySpeed={4000} draggable autoplay>
                {post.thumbnailUrls.map((item, key) => (
                  <Image
                    key={key}
                    preview
                    className={classes.blogDetailImage}
                    src={item}
                    alt={`post-detail-${key}`}
                    isBgPlaceholder
                  />
                ))}
              </Carousel>
            )}
          </Col>
          <Col sm={24} xs={24} md={12}>
            <Row gutter={[0, isMobile ? 24 : 40]}>
              <Col span={24}>
                <h1
                  className={` ${classes.sectionHeader} ${classes.fontMid} ${classes.stripLeft} ${classes.flexColumn}`}
                >
                  {post.title}
                  <Space align="center" direction="horizontal">
                    <Avatar
                      style={{
                        backgroundColor: _THEME.color.primary.main,
                      }}
                      icon={<UserOutlined />}
                    />
                    <Space direction="vertical" size={[0, 4]}>
                      <h6 className={classes.fontMid + " " + classes.textMd1}>
                        {post.author.name}
                      </h6>
                      <p
                        className={classes.colorSubText + " " + classes.textSm3}
                      >
                        {Math.floor(Math.abs(new Date() - new Date(post.createdAt)) / (1000 * 60 * 60 * 24))} days ago
                      </p>
                    </Space>
                  </Space>
                </h1>
              </Col>
              {post.description && (
                <Col span={24}>
                  <p
                    className={` ${classes.textMd1} ${classes.paraLine} ${classes.headerLine}`}
                  >
                    {post.description}
                  </p>
                </Col>
              )}
              <Col span={24}>
                <Row gutter={[16, 24]}>
                  <Col span={12}>
                    <Statistic
                      className={classes.statWrap}
                      title="Gender"
                      value={post.gender}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      className={classes.statWrap}
                      title="Sterilized"
                      value={post.sterilization}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      className={classes.statWrap}
                      title="Place Found"
                      value={post.location.name}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      className={classes.statWrap}
                      title="Transport"
                      value={post.transportation}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Button
                  onClick={toggleDownloadPromptModal}
                  size="large"
                  icon={<MessageOutlined />}
                  type="primary"
                  block
                >
                  GET IN TOUCH
                </Button>
              </Col>
              <Col span={24}>
                <Space size={24}>
                  <label
                    className={
                      classes.textSm3 +
                      " " +
                      classes.paraLine +
                      " " +
                      classes.fontRegular
                    }
                  >
                    Share via:
                  </label>
                  <Flex gap={12} wrap>
                    <FacebookShareButton
                      url={`https://unstray.me/post-details/${post.id}`}
                    >
                      <Button
                        size="large"
                        type="default"
                        icon={<FacebookFilled />}
                      />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={`https://unstray.me/post-details/${post.id}`}
                      title="Check this out: "
                    >
                      <Button
                        size="large"
                        type="default"
                        icon={<WhatsAppOutlined />}
                      />
                    </WhatsappShareButton>
                  </Flex>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <InquiryModal
        toggleModal={toggleInquiryModal}
        isModelOpen={isInquiryModalOpen}
        onFormSubmit={async (dt) => {
          try {
            setIsInquirySending(true);

            await postInquiry({
              ...dt,
              postId: post.id,
              userId: post.createdById,
            });

            notify({
              message: "Your inquiry has been sent",
            });
          } catch (err) {
            notify({
              type: "error",
              message: "Something went wrong",
            });
          } finally {
            setIsInquirySending(false);
          }
        }}
      />
      <DownloadPromptModal
        toggleModal={toggleDownloadPromptModal}
        isModelOpen={isDownloadPromptModalOpen}
      />
    </CustomContainer>
  );
};

export default PostDetails;

const PostDetailsSkeleton = (props) => {
  const { deviceType } = useMetaContext();

  const isResponsive = deviceType !== DeviceType.pc;

  if (isResponsive) {
    return (
      <CustomContainer padTop="lg" padBottom="lg">
        <Row
          gutter={[
            { sm: 0, xs: 0, md: 24 },
            { xs: 16, sm: 16 },
          ]}
        >
          <Col sm={24} xs={24} md={12}>
            <ContentLoader
              speed={2}
              width={"100%"}
              height={isResponsive ? 250 : 400}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect width="100%" height="500" />
            </ContentLoader>
          </Col>
          <Col sm={24} xs={24} md={12}>
            <ContentLoader
              speed={2}
              width={"100%"}
              height={"100%"}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect x="0" y="0" width="100%" height="40" />
              <rect x="0" y="60" width="100%" height="40" />
              <rect x="0" y="120" width="100%" height="40" />
            </ContentLoader>
          </Col>
        </Row>
      </CustomContainer>
    );
  }
  return (
    <CustomContainer padTop="lg" padBottom="lg">
      <Row
        gutter={[
          { sm: 0, xs: 0, md: 24 },
          { xs: 16, sm: 16 },
        ]}
      >
        <Col sm={24} xs={24} md={12}>
          <ContentLoader
            speed={2}
            width={"100%"}
            height={isResponsive ? 250 : 400}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect width="100%" height="500" />
          </ContentLoader>
        </Col>
        <Col sm={24} xs={24} md={12}>
          <ContentLoader
            speed={2}
            width={"100%"}
            height={"100%"}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="0" y="0" width="100%" height={"80"} />
            <rect x="0" y="136" width="100%" height="40" />
            <rect x="0" y="200" width="100%" height="40" />
            <rect x="0" y="264" width="100%" height="40" />
          </ContentLoader>
        </Col>
      </Row>
    </CustomContainer>
  );
};
